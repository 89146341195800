import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import { useIntl } from "react-intl"

import Modal from "~/components/Modals/Modal"
import { Body, Button, Heading, HrefButton } from "~/components/ui"

import css from "./CommunityFeedModal.module.scss"

interface DataTypes {
  phone: FileNode
}

function useImage(): DataTypes {
  return useStaticQuery(graphql`
    query {
      phone: file(relativePath: { eq: "CommunityFeed/Phone11-pro-back.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, placeholder: BLURRED)
        }
      }
    }
  `)
}

export type ModalType = "comment" | "post"

interface PropTypes {
  type: ModalType | null
  onClose: () => void
}

function CommunityFeedModal({ type, onClose }: PropTypes) {
  const images = useImage()
  const phone = getImage(images.phone)!
  const intl = useIntl()
  const title = intl.formatMessage({
    id:
      type === "comment"
        ? "community/section:title-to-share-a-comment-in-the-app"
        : "community/section:title-to-share-a-photo-in-the-app",
  })

  const text = intl.formatMessage({
    id:
      type === "comment"
        ? "community/text:text-to-share-a-comment-in-the-app"
        : "community/text:text-to-share-a-photo-in-the-app",
  })

  return (
    <Modal open={!!type} onClose={onClose} mode="fixed">
      {modalRef => (
        <div className={css.modal} ref={modalRef}>
          {!!type && (
            <>
              <div className={css.card}>
                <div className={css.header}>
                  <span className={css.closeCross} onClick={onClose} />
                  <span
                    className={
                      type === "comment" ? css.redStarIcon : css.purpleStarIcon
                    }
                  />
                  <span className={css.smallYellowStarIcon} />
                  <span className={css.largeYellowStarIcon} />
                  <span
                    className={
                      type === "comment"
                        ? css.cyanCircleIcon
                        : css.blueCircleIcon
                    }
                  />
                  <span
                    className={
                      type === "comment"
                        ? css.purpleWaveIcon
                        : css.orangeWaveIcon
                    }
                  />
                  <span
                    className={
                      type === "comment" ? css.commentIcon : css.cakeIcon
                    }
                  />

                  <GatsbyImage className={css.phone} image={phone} alt="" />
                </div>
                <div className={css.content}>
                  <Heading variant="large" align="center">
                    {title}
                  </Heading>
                  <Body noMargin align="center" variant="body1">
                    {text}
                  </Body>
                  <HrefButton
                    href=" https://chefclubtv.app.link/03faQqek3bb?utm_source=chefclub.tv&utm_campaign=communauté&utm_medium=downloadAppButton"
                    className={css.button}
                  >
                    {intl.formatMessage({
                      id: "community/action:download-the-app",
                    })}
                  </HrefButton>
                  <Button onClick={onClose} variant="tertiary" underline>
                    {intl.formatMessage({
                      id: "community/action:i-am-already-a-star-in-the-app",
                    })}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  )
}

export default CommunityFeedModal
